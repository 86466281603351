import { devtoolsExchange } from "@urql/devtools";
import { withUrqlClient } from "next-urql";
import { cacheExchange, dedupExchange, fetchExchange } from "urql";

import ProjectList from "../components/ProjectList";

export default withUrqlClient(
  (ssrExchange) => ({
    exchanges: [
      devtoolsExchange,
      dedupExchange,
      cacheExchange,
      ssrExchange,
      fetchExchange,
    ],
    suspense: true,
    url: typeof window === "undefined" ? "http://localhost:3000/api" : "/api",
  }),
  { ssr: true }
)(ProjectList);
