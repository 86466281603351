import { css } from "@emotion/react";
import { Button, Container, Stack, Typography } from "@material-ui/core";
import Link from "next/link";

import { useProjectsQuery } from "./Projects.generated";

const ProjectList = (): JSX.Element => {
  const [{ data }] = useProjectsQuery();

  const projects = data?.projects;

  if (!projects) {
    throw new Error("No projects");
  }

  return (
    <Container>
      <Typography align="center" gutterBottom variant="h1">
        Projects
      </Typography>
      <Stack
        css={css`
          margin: 0 auto;
          max-width: 400px;
        `}
        spacing={2}
      >
        {projects.map((project) => (
          <Link key={project.id} href={`/projects/${project.id}`} passHref>
            <Button variant="contained">{project.name}</Button>
          </Link>
        ))}
      </Stack>
    </Container>
  );
};

export default ProjectList;
