import type * as Types from "../../../schema.generated";

import gql from "graphql-tag";
import * as Urql from "urql";
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ProjectsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ProjectsQuery = {
  __typename?: "Query";
  projects: Array<{ __typename?: "TimelineProject"; id: string; name: string }>;
};

export const ProjectsDocument = gql`
  query Projects {
    projects {
      id
      name
    }
  }
`;

export function useProjectsQuery(
  options: Omit<Urql.UseQueryArgs<ProjectsQueryVariables>, "query"> = {}
) {
  return Urql.useQuery<ProjectsQuery>({ query: ProjectsDocument, ...options });
}
